var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"pre-20241014-7"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import process from 'process';
import * as Sentry from '@sentry/nextjs';
import { ProfilingIntegration } from '@sentry/profiling-node';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  release: process.env.NEXT_PUBLIC_RELEASE_VERSION || '0.0.0',
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || 'local',
  includeLocalVariables: true,
  tracesSampleRate: 1,
  debug: false,
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  maxValueLength: 5096,
  normalizeDepth: 12,
  normalizeMaxBreadth: 2000,
  attachStacktrace: true,
  maxBreadcrumbs: 200,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb.category === 'ui.click' || breadcrumb.category === 'ui.input') {
      return null;
    }

    if (breadcrumb.category === 'xhr' && breadcrumb.data?.url?.includes('/graphql')) {
      return null;
    }

    if (breadcrumb.category === 'http' && breadcrumb.data?.url?.includes('livediag')) {
      return null;
    }

    if (breadcrumb.category === 'xhr' && breadcrumb.data?.url?.includes('linkedin')) {
      return null;
    }

    if (breadcrumb.category === 'xhr' && breadcrumb.data?.url?.includes('fullstory')) {
      return null;
    }

    if (breadcrumb.category === 'xhr' && breadcrumb.data?.url?.includes('hotjar')) {
      return null;
    }

    if (breadcrumb.category === 'fetch' && breadcrumb.data?.url?.includes('/__nextjs_original-stack-frame')) {
      return null;
    }

    if (breadcrumb.category === 'fetch' && breadcrumb.data?.url?.includes('ota.eu.phrase.com')) {
      return null;
    }

    if (breadcrumb.category === 'fetch' && breadcrumb.data?.url?.includes('cdn.cookielaw.org')) {
      return null;
    }

    if (breadcrumb.category === 'fetch' && breadcrumb.data?.url?.includes('sdk-api.devcycle.com')) {
      return null;
    }

    if (breadcrumb.category === 'console' && breadcrumb.message?.includes('i18next')) {
      return null;
    }

    if (breadcrumb.category === 'console' && breadcrumb.message?.includes('DevCycle')) {
      return null;
    }

    if (breadcrumb.category === 'http' && breadcrumb.data?.url?.includes('livediagnostics')) {
      return null;
    }

    if (breadcrumb.category === 'http' && breadcrumb.data?.url?.includes('applicationinsights')) {
      return null;
    }

    if (breadcrumb.category === 'http' && breadcrumb.data?.url?.includes('telemetry.nextjs')) {
      return null;
    }

    return breadcrumb;
  },
  beforeSend(event, hint) {
    const error = hint.originalException;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const errorMessage = error?.message;

    event.fingerprint = [
      errorMessage,
    ];

    if (
      errorMessage?.match(/Request failed with status code 401/i) ||
      errorMessage?.match(/Failed to load translations/i) ||
      errorMessage?.match(/Network request failed/i) ||
      errorMessage?.match(/claim timestamp check failed/i) ||
      errorMessage?.match(/aborted/i) ||
      errorMessage?.match(/the cell with id/i) ||
      errorMessage?.match(/password complexity requirements/i) ||
      errorMessage?.match(/next-i18next was unable to find a user config at/i)
    ) {
      return null;
    }

    return event;
  },
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    'Script error.',
    // Avast extension error
    '_avast_submit',
    // This seems to get thrown when we get errors in the error handler that are not constructed right
    'Non-Error exception captured',
  ],
  denyUrls: [
    // Google Adsense
    /pagead\/js/i,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
});
